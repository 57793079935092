const env = import.meta.env

const getAuthDomain = () => {
  const hostname = window.location.hostname
  if (hostname.includes('.moleskinestudio.com')) {
    return hostname
  }
  if (hostname.includes('.bonobolabs.com')) {
    return hostname
  }

  // use firebase auth for local dev and other PR builds
  return env.VITE_FIREBASE_AUTHDOMAIN
}

export const firebaseConfig = {
  apiKey: env.VITE_FIREBASE_APIKEY,
  databaseURL: env.VITE_FIREBASE_DATABASE_URL,
  projectId: env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: env.VITE_FIREBASE_STORAGEBUCKET,
  messagingSenderId: env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  authDomain: getAuthDomain()
}

export const FIRESTORE_STORAGE_URL = env.VITE_FIRESTORE_STORAGE_URL

export const FathomToken = env.VITE_FATHOM

export const serverName = env.VITE_FIREBASE_PROJECT_ID

const getEnv = () => {
  if (env.DEV) {
    return 'development'
  }
  if (serverName === 'msksuite-11ba2') {
    return 'production'
  } else if (serverName === 'msksuite-staging') {
    return 'staging'
  }
}

export const environment = getEnv()
