import themeGet from '@styled-system/theme-get'
import React, { useCallback } from 'react'
import { Link as ReachRouterLink, navigate } from '@reach/router'

import { PrimaryButton, SecondaryButton, InlineLink } from 'mds-web-ui'
import { color, layout, space, typography } from 'styled-system'
import styled from 'styled-components'
import shouldForwardProp from '@styled-system/should-forward-prop'

const StyledLink = styled(ReachRouterLink)
  .attrs(props => ({
    color: props.color ? props.color : themeGet('colors.foreground', '#eeeeee')(props)
  }))
  .withConfig({
    shouldForwardProp
  })(
  {
    textDecoration: 'none',
    '-webkit-user-drag': 'none',
    cursor: 'pointer',
    opacity: 0.9,
    transition: 'opacity 120ms ease-out',
    '&:hover': {
      opacity: 1
    }
  },
  color,
  typography
)

///////////////////////////////////////////////////////////////////////////////

const CleanRouterLink = styled(ReachRouterLink)
  .withConfig({
    shouldForwardProp
  })
  .attrs(props => ({
    lineHeight: [2, 3],
    color: 'inherit',
    ...props
  }))(
  {
    display: 'inline-block',
    textDecoration: 'none',
    transition: 'opacity ease-in-out 0.1s',
    '&:hover': {
      opacity: 0.8
    }
  },
  space,
  layout,
  color,
  typography
)

export const DefaultLink = CleanRouterLink

export const Link = ({
  LinkComponent = InlineLink,
  isActive,
  external = false,
  to,
  inline = false,
  newTab = false,
  ...props
}) => {
  const _LinkComponent = external ? LinkComponent : DefaultLink
  const linkProps = { ...props }

  if (external) {
    // Anchor tag by default
    linkProps.href = to
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    linkProps.rel = 'noopener'
  } else {
    // react-router
    linkProps.to = to
  }
  if (newTab) {
    linkProps.target = '_blank'
  }
  if (isActive) {
    linkProps.fontWeight = 'bold'
  }

  if (inline) {
    return <_LinkComponent {...linkProps} />
  } else {
    return (
      <div>
        <_LinkComponent {...linkProps} />
      </div>
    )
  }
}

Link.displayName = 'Link'

///////////////////////////////////////////////////////////////////////////////
// Active Link - passes isActive down to child element (which defaults to Link)
export const ActiveLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => ({
      isActive: isCurrent
    })}
  />
)

///////////////////////////////////////////////////////////////////////////////
// for linking to local urls e.g. href='/'
export const ButtonLinkLocal = ({ type = 'secondary', href, ...rest }) => {
  // TODO check modifier keys pressed
  const onClick = useCallback(() => navigate(href), [href])

  if (type === 'secondary') {
    return <SecondaryButton {...rest} as="a" onClick={onClick} />
  } else if (type === 'primary') {
    return <PrimaryButton {...rest} as="a" onClick={onClick} />
  }

  throw Error('Invalid ButtonLinkLocal type')
}

export default StyledLink
