import React from 'react'
// import ReactDOM from 'react-dom/client'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'

// Enable sentry in production
if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://68b912208e32fc7eb367db2890009218@o164846.ingest.sentry.io/4505751861395456',
    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/flowapp\.bonobolabs\.com\//]
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

// There's a major issue with reach router and react 18 https://github.com/reach/router/issues/504 for which there isn't really any solve
// apart from migrating to react-router. This is a temporary workaround until we can migrate to react-router, which is a significant amount of work.
// ReactDOM.createRoot(document.getElementById('root')).render(<App />)
ReactDOM.render(<App />, document.getElementById('root'))
